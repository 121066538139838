/**
 * Удаление лоадера из dom дерева после загрузки страницы
 * Скрипт отрабатывает в любом случае, даже если есть ошибки в JS
 */
window.onload = function() {
    $('body').removeClass('loaded');
    let loader = document.querySelector('.loader');
    loader.parentNode.removeChild(loader);
};
$(document).ready(function () {
    /** Глобальные переменные */
    var WIN = $(window),
        DOC = $(document),
        HTML = $('html'),
        BODY = $('body')

    const feedbackSlider = new Swiper('[data-slider-feedback]', {
        loop: true,
        autoplay: true,
        speed: 1000,
        slidesPerView: 1,
        spaceBetween: 40,
        navigation: {
            nextEl: '.feedback-slider-next',
            prevEl: '.feedback-slider-prev',
        },
        breakpoints: {
            980: {
                slidesPerView: 2,
                spaceBetween: 40,
            }
        }
    });
    const featuresSlider = new Swiper ('[data-slider-features]', {
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-half-next',
            prevEl: '.swiper-half-prev',
        },
        breakpoints: {
            680: {
                direction: 'vertical',
            }
        }

    })
    const activitySlider = new Swiper ('[data-slider-activity]', {
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-half-next',
            prevEl: '.swiper-half-prev',
        },
        breakpoints: {
            680: {
                direction: 'vertical',
            }
        }

    })

    const sliderRoom = new Swiper("[data-slider-room-thumbs]", {
        loop: true,
        spaceBetween: 10,
        slidesPerView: 3,
        slidesPerGroup: 3,
        freeMode: false,
        watchSlidesProgress: true,

    })
    const sliderRoomThumbs = new Swiper("[data-slider-room]", {
        loop: true,
        spaceBetween: 15,
        thumbs: {
            swiper: sliderRoom,
        },
        navigation: {
            nextEl: '.slider-room-next',
            prevEl: '.slider-room-prev',
        },
    })



    const sliderActivitiesPreview = new Swiper("[data-slider-activity-preview]", {
        direction: 'horizontal',
        spaceBetween: 10,
        slidesPerView: 3,
        slidesPerGroup: 3,
        freeMode: false,
    })
    const sliderActivities = new Swiper("[data-slider-activity-item]", {
        direction: 'horizontal',
        spaceBetween: 10,
        thumbs: {
            swiper: sliderActivitiesPreview,
        },
    })


    /**
     * Событие клика на элементе faq. Показывает\скрывает ответ на вопрос
     */
    DOC.on('click', '[data-faq-question]', function() {
        let item = $(this).parents('.faq__item');
        // item.find('.faq__answer').slideToggle();
        item.toggleClass('faq--open');
    });


    /** Мобильное меню **/
// десктоп
    DOC.on('click', '[data-mobile-nav]:not(.opened) i', function (event) {
        event.preventDefault()
        $('[data-mobile-nav]').addClass('opened')
    })
    DOC.on(
        'click',
        '[data-mobile-nav].opened [data-mobile-nav-opener]',
        function () {
            $('[data-mobile-nav]').removeClass('opened')
        }
    )

// мобильное меню бургер
    function mobileMenu() {
        let opener = $('[data-burger-menu-opener]')
        let wrapper = $('[data-burger-menu]')
        let closer = $('[data-burger-menu-closer]')
        opener.on('click', function (e) {
            e.preventDefault()
            let that = $(e.target)
            if (!that.hasClass('close')) {
                wrapper.addClass('opened')
                $('body').addClass('fixed')
            } else {
                wrapper.removeClass('opened')
                $('body').removeClass('fixed')
            }
        })
        closer.on('click', function (e) {
            e.preventDefault()
            wrapper.removeClass('opened')
            $('body').removeClass('fixed')
        })
    }

    mobileMenu()

    /** END Мобильное меню **/

        let addInput = $('[data-persons]');
        let n = 1;
        addInput.val(n);
        $(".plus").on("click", function () {
            addInput.val(++n);
        });

        $(".minus").on("click", function () {
            if (n > 1) {
                addInput.val(--n);
            } else {
            }
        });

    /**
     * Инициализация select menu
     * Используется в формах, которые подгружаются на ajax
     * Для этого вынесен в функцию для использования внутри ajaxSuccess
     **/
    function selectInit() {
        $('[data-select-menu]').each(function () {
            if (!$(this).parent().hasClass('selectric-hide-select')) {
                $(this).selectric({
                    maxHeight: 320,
                    disableOnMobile: false,
                    nativeOnMobile: false,
                    arrowButtonMarkup:
                        '<b class="selectric__button icon-arrow-down"></b>',
                    optionsItemBuilder: function(itemData, element, index) {
                        return element.val().length ? '<i class="icon-' + index +  '"></i>' + itemData.text : itemData.text;
                    },
                    onInit: function () {
                        var current_option = $(this),
                            current_option_value = current_option.val(),
                            selectric_container = $(this)
                                .parents('.selectric-wrapper')
                                .find('.selectric')

                        // проверка для вывода поля с секретным ответом
                        if (
                            typeof current_option.attr('data-secret-question') !==
                            typeof undefined &&
                            current_option.attr('data-secret-question') !== false
                        ) {
                            if (current_option_value) {
                                secret_answer.show()
                            } else {
                                secret_answer.hide()
                            }
                        }

                        //  эмуляция placeholder'a для селектрика
                        if (current_option_value) {
                            selectric_container.removeClass('selectric--placeholder')
                        } else {
                            selectric_container.addClass('selectric--placeholder')
                        }

                        // проверка на количество эллементов, если 1 - то добавляем класс,
                        // который скрывает всю навигацию
                        if (current_option.find('option').length === 1) {
                            current_option
                                .parents('.selectric-wrapper')
                                .addClass('selectric--void')
                        }
                    },
                    onChange: function (element) {
                        var current_option = $(this),
                            current_option_value = current_option.val(),
                            selectric_container = $(this)
                                .parents('.selectric-wrapper')
                                .find('.selectric')

                        // проверка для вывода поля с секретным ответом
                        if (
                            typeof current_option.attr('data-secret-question') !==
                            typeof undefined &&
                            current_option.attr('data-secret-question') !== false
                        ) {
                            if (current_option_value) {
                                secret_answer.show()
                            } else {
                                secret_answer.hide()
                            }
                        }

                        //  эмуляция placeholder'a для селектрика
                        if (current_option_value) {
                            selectric_container.removeClass('selectric--placeholder')
                        } else {
                            selectric_container.addClass('selectric--placeholder')
                        }
                    },
                })
            }
        })
    }
    selectInit()
    /** End **/


    DOC.on('click', '[data-submenu]', function(){
        if(!$(this).parents('.header-menu__item').find('.header-submenu').is(':visible')){
            // $(this).addClass('opened');
            $('.header-submenu:visible').hide();
        }
        $(this).parents('.header-menu__item').find('.header-submenu').toggle();
    });

    const localeEn = {
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        daysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        clear: 'Clear',
        dateFormat: 'dd.MM.yyyy',
        timeFormat: 'hh:mm aa',
        firstDay: 0
    };

    new AirDatepicker('[data-booking-calendar]', {
        locale: localeEn,
        inline: true,
        autoClose: true
    });
    new AirDatepicker('[data-time]', {
        locale: localeEn,
        timepicker: true,
        timeFormat: 'hh:mm AA',
        autoClose: true,
    });

    const inputs = document.querySelectorAll('input[data-date]');

    inputs.forEach((input) => {
        new AirDatepicker(input, {
            locale: localeEn,
            autoClose: true,
            // range: true,
            // multipleDatesSeparator: ' - '
        });
    });

// при ресайзе (только горизонтальном) эмулируем клик на body, из-за этого срабатывает скрипт закрытия блока меню
    var w = $(window).width()

    WIN.on('resize', function () {
        if ($(window).width() == w) return
        w = $(window).width()
        BODY.trigger('click')
    })
})
